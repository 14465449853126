@import "../../assets/styles/utils";


.index-title{
  @include res(margin-top, 1.75rem,(xs:1rem));
  @include res(margin-bottom, .4rem);
  .en-title{
    color: $color-main-dark-text;
    font-family: 'Nexa_Bold';
    display: flex;
    align-items: center;
    @include res(margin-bottom, .3rem,(xs:.1rem));
    @include res(font-size, .2rem,(md:.3rem,sm:.4rem,xs:.3rem));
    &::after{
      content: '';
      display: block;
      flex-grow: 1;
      background-color: #cecece;
      @include res(margin-left,.2rem);
      @include res(height, .01rem,(md: 1px));
    }
  }
  .cn-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1{
      color: #000;
      font-weight: bold;
      @include res(font-size, .45rem,(md:.55rem,sm:.65rem,xs:.5rem));
    }
  }
}
.section1{
  position: relative;
  z-index: 2;
  @include res(height,100vh);
  .swiper{
    height: 100%;
    .swiper-slide{
      overflow: hidden;
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.3);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }
      b{
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
      }
      video{
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        object-fit: cover;
      }
    }
  }
  .banner-t{
    color: #fff;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
    @include res(bottom,1.3rem);
    .t1{
      @include res(font-size,.38rem,(md:.48rem,sm:.56rem,xs:.4rem));
    }
    .t2{
      @include res(margin-top, .05rem);
      @include res(font-size,.58rem,(md:.68rem,sm:.78rem,xs:.54rem));
    }
    .btn{
      display: flex;
      align-items: center;
      @include res(margin-top, .4rem);
      @include res(font-size, .16rem,(md:.26rem,sm:.36rem,xs:.24rem));
      &::before{
        content: '';
        display: block;
        flex-grow: 1;
        background-color: rgba(255,255,255,.3);
        @include res(height, .01rem,(md:1px));
      }
      span{
        @include res(margin-left,.15rem);
        @include res(margin-right,.15rem);
      }
      .icon{
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include res(width,.5rem,(md:.6rem,sm:.78rem,xs:.7rem));
        @include res(height,.5rem,(md:.6rem,sm:.78rem,xs:.7rem));
        .iconfont{
          color: $color-main-dark;
          animation: bandown 1.2s infinite linear;
          @include res(font-size, .2rem,(md:.3rem,sm:.4rem,xs:.34rem));
        }
      }
    }
  }
}
@keyframes bandown {
  0%{
    opacity: 0;
    transform: translateY(-30%);
  }
  30%{
    opacity: 1;
  }
  50%{
    opacity: 1;
    transform: translateY(0);
  }
  70%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    transform: translateY(50%);
  }
}

.section2{
  .sec2-flex{
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    @include res(height,3.9rem,(sm:6rem,xs:5rem));
    .sec2-text{
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
      @include res(display,flex,(xs:block));
      .left{
        flex-shrink: 0;
        line-height: 1.6;
        color: #000;
        @include res(width, 63vw,(xs:100%));
        @include res(font-size,.45rem,(md:.55rem,sm:.64rem,xs:.44rem));
      }
      .link{
        flex-shrink: 0;
        @include res(margin-top,0,(xs:.3rem));
        @include res(text-align,ull,(xs:right));
      }
    }
  }
  .sec2-cont{
    position: relative;
    z-index: 1;
    @include res(display,flex,(sm:block));
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 15%;
      position: absolute;
      bottom: 0;
      left: 0;
      pointer-events: none;
      z-index: 2;
      background-image: linear-gradient(to top, rgba(233,244,255,1),rgba(233,244,255,0));
      border-bottom-left-radius: .2rem;
      border-bottom-right-radius: .2rem;
    }
    .bg{
      position: absolute;
      bottom: 0;
      z-index: -1;
      background-image: linear-gradient(to bottom, #4b9fe4,#eaf2ff);
      filter: blur(20px);
      transition: all 1.2s cubic-bezier(0.01, 0.17, 0.28, 0.96);
      transform: scale(1.05);
      @include res(width, calc(100% + 8.32vw),(md:calc(100% + 2rem),xs:calc(100% + .6rem)));
      @include res(height, calc(100% + 3.9rem),(sm:calc(100% + 6rem),xs:calc(100% + 5rem)));
      @include res(left,-4.16vw,(md:-1rem,xs:-.3rem));
      @include res(opacity, .4);
      @include res(border-radius, 0);
      
      &.active{
        width: 100%;
        height: 100%;
        left: 0;
        border-radius: .2rem;
        opacity: 1;
        filter: blur(0px);
        transform: scale(1);
      }
    }
    .left{
      flex-shrink: 0;
      position: relative;
      @include res(width,51.13%,(sm:100%));
      .location-list{
        li{
          position: absolute;
          width: 1px;
          height: 1px;
          white-space: nowrap;
          span{
            color: #000;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: color .3s;
            @include res(right, .25rem);
            @include res(font-size, .22rem,(md:.32rem,sm:.42rem,xs:.3rem));
          }
          .icon{
            border-radius: 50%;
            background-color: $color-main-light;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            transform: translate3d(-50%,-50%,0);
            @include res(width,.13rem,(md:.16rem,sm:.2rem,xs:.16rem));
            @include res(height,.13rem,(md:.16rem,sm:.2rem,xs:.16rem));
            &::before{
              content: '';
              display: block;
              width: 100%;
              height: 100%;
              background-color: $color-main-light;
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
              transform-origin: center;
              border-radius: 50%;
              animation: mapscroll 2s infinite;
              animation-delay: 1s;
            }
            &::after{
              content: '';
              display: block;
              width: 100%;
              height: 100%;
              background-color: $color-main-light;
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
              transform-origin: center;
              border-radius: 50%;
              animation: mapscroll 2s infinite;
            }
          }
          &:nth-child(1){
            top: 57%;
            left: 72.5%;
            .icon{
              background-color: #d53030;
              &::before,&::after{
                background-color: #d53030;
              }
            }
          }
          &:nth-child(2){
            top: 79%;
            left: 40.5%;
          }
          &:nth-child(3){
            top: 44%;
            left: 75.5%;
          }
          &.active{
            span{
              color: #000;
            }
            .icon{
              &::before{
                animation: mapscroll 2s infinite;
                animation-delay: 1s;
              }
              &::after{
                animation: mapscroll 2s infinite;
              }
            }
          }
        }
      }
      .location-tab{
        position: absolute;
        z-index: 2;
        border-radius: .15rem;
        overflow: hidden;
        background-color: rgba(255,255,255,.38);
        white-space: nowrap;
        @include res(top,5.2vw,(md:2.2vw));
        @include res(left,7.29vw,(md:2.29vw));
        @include res(font-size, .2rem,(md:.3rem,sm:.4rem,xs:.24rem));
        li{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          transition: color .3s, background-color .3s;
          @include res(padding,.2rem .25rem,(xs:.15rem .1rem));
          &::before{
            content: '';
            display: block;
            background-color: $color-main-light;
            border-radius: 50%;
            transition: background-color .3s;
            @include res(width,.08rem,(md:.1rem,sm:.14rem,xs:.08rem));
            @include res(height,.08rem,(md:.1rem,sm:.14rem,xs:.08rem));
            @include res(margin-left,.05rem);
            @include res(margin-right,.15rem);
          }
          &:nth-child(1){
            &::before{
              background-color: #d53030;
            }
          }
          &.active{
            color: #fff;
            background-color: $color-main-light;
            &::before{
              background-color: #fff;
            }
            &:nth-child(1){
              background-color: #d53030;
            }
          }
        }
      }
    }
    .right{
      flex-shrink: 0;
      position: relative;
      z-index: 1;
      @include res(width,48.87%,(sm:100%));
      .num-cont{
        display: flex;
        flex-wrap: wrap;
        white-space: nowrap;
        color: #000;
        @include res(justify-content,flex-start,(sm:center));
        @include res(font-size, .16rem,(md:.26rem,sm:.36rem,xs:.24rem));
        @include res(margin-top,1rem,(xs:.5rem));
        .num-item{
          box-sizing: border-box;
          @include res(text-align,null,(xs:center));
          @include res(min-width,33.33%,(sm:auto));
          @include res(margin-bottom,.25rem,(sm:.5rem));
          @include res(padding-left,.2rem,(md:.2rem,sm:1rem,xs:.4rem));
          @include res(padding-right,0,(sm:1rem,xs:.4rem));
          .t1{
            display: inline-flex;
            align-items: flex-end;
            justify-content: flex-start;
            position: relative;
            @include res(margin-bottom, .1rem);
            .number{
              display: inline-block;
              position: relative;
              font-family: 'Nexa_Bold';
              color: $color-main-dark-text;
              @include res(font-size, .6rem,(sm:1rem,xs:.7rem));
              @include res(line-height,.6rem,(sm:1rem,xs:.7rem));
              .zw-num{
                opacity: 0;
              }
              .num{
                position: absolute;
                top: 0;
                left: 0;
                .dataNums{
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  @include res(height,.6rem,(sm:1rem,xs:.7rem));
                  .dataOne{
                    @include res(width,.4rem,(sm:.66rem,xs:.46rem));
                    @include res(height,.6rem,(sm:1rem,xs:.7rem));
                    &.dh{
                      box-sizing: border-box;
                      @include res(width,.4rem);
                    }
                  }
                  .dataBoc {
                    position: relative; 
                    width: 100%; 
                    height: 100%; 
                    overflow: hidden;
                    .tt {
                      position: absolute; 
                      top: 0;  
                      left: 0; 
                      width: 100%;  
                      height: 100%;
                      span{
                        width:100%;
                        height:100%;
                        float: left;
                        display: flex;
                        align-items: center;
                      }
                    }
                  }
                }
              }
            }
            .jia{
              position: absolute;
              top: 0;
              left: 100%;
              color: $color-main-dark-text;
              line-height: .2;
              @include res(font-size,.3rem,(md:.4rem,sm:.6rem,xs:.46rem));
            }
            .dw{
              @include res(margin-left, .15rem,(md:.2rem));
              @include res(margin-bottom, .07rem);
            }
          }
        }
      }
      .img{
        bottom: 0;
        right: 0;
        z-index: -1;
        display: flex;
        justify-content: flex-end;
        pointer-events: none;
        @include res(position,absolute,(sm:static));
        @include res(margin-top,0,(xs:-10%));
        img{
          display: block;
          height: auto;
          border-bottom-right-radius: .2rem;
          @include res(width, 125%,(sm:100%,xs:110%));
        }
      }
    }
  }
}
@keyframes mapscroll {
  0%{
    opacity: .8;
    transform: scale(1);
  }
  100%{
    opacity: 0;
    transform: scale(3);
  }
}

.section3{
  ul{
    @include res(flex-wrap,nowrap,(sm:wrap));
    @include res(width,calc(100% + .25rem),(xs:100%));
    @include res(display,flex,(xs:block));
    li{
      position: relative;
      flex-shrink: 0;
      overflow: hidden;
      border-radius: .2rem;
      background-color: #2543ab;
      transition: width .4s;
      @include res(margin-right, .25rem);
      @include res(width, calc(25% - .25rem),(sm:calc(50% - .25rem),xs:100%));
      @include res(height,39.8vw,(sm:48.8vw,xs:101.8vw));
      @include res(margin-bottom,0,(sm:.25rem));
      .img{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left bottom;
      }
      .title{
        color: #fff;
        white-space: nowrap;
        position: relative;
        z-index: 1;
        @include res(padding-top,1rem,(xs:.6rem));
        @include res(padding-left,.45rem);
        @include res(font-size,.35rem,(md:.45rem,sm:.6rem,xs:.48rem));
      }
      .link{
        position: absolute;
        z-index: 1;
        transition: opacity .3s;
        transition-delay: 0s;
        @include res(opacity,0,(sm:1));
        @include res(bottom,.45rem);
        @include res(right,.4rem);
      }
      &:nth-child(1){
        background-image: linear-gradient(to bottom,#2542ab,#25b4ac);
      }
      &:nth-child(2){
        background-image: linear-gradient(to bottom,#1868d4,#30c9cd);
        .img{
          background-position: right bottom;
        }
      }
      &:nth-child(3){
        background-image: linear-gradient(to bottom,#2b7bb2,#a4c9d0);
        .img{
          background-position: right bottom;
        }
      }
      &:nth-child(4){
        background-image: linear-gradient(to bottom,#077bbb,#c58d8e);
      }
      &.active{
        @include res(width, calc(37% - .25rem),(sm:calc(50% - .25rem),xs:100%));
        .link{
          opacity: 1;
          transition-delay: .4s;
        }
      }
      &.other{
        @include res(width, calc(21% - .25rem),(sm:calc(50% - .25rem),xs:100%));
      }
    }
  }
}

.section4{
  .sec4-swiper{
    justify-content: space-between;
    flex-direction: row-reverse;
    @include res(display,flex,(sm:block));
    @include res(margin-bottom, .6rem);
    .img{
      position: relative;
      flex-shrink: 0;
      border-radius: .1rem;
      overflow: hidden;
      @include res(width,29.79vw,(sm:100%));
      @include res(height,18.95vw,(md:21.95vw,sm:45.95vw,xs:55vw));
      a{
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-position: center;
        background-size: cover;
        transition: transform .3s;
        &:hover{
          transform: scale(1.1);
        }
      }
    }
    .swiper{
      border-radius: .1rem;
      overflow: hidden;
      background-color: #f4f4f4;
      margin: 0;
      transition: background-color .3s;
      @include res(width,calc(100% - .2rem - 29.79vw),(sm:100%));
      .swiper-slide{
        box-sizing: border-box;
        @include res(padding, .7rem,(sm:1.2rem,xs:.5rem .7rem));
        @include res(padding-bottom, 1rem,(sm:1.5rem,xs:1rem));
        .title{
          color: #000;
          @extend %ellipsis;
          @include res(font-size, .3rem,(md:.4rem,sm:.56rem,xs:.4rem));
          @include res(margin-bottom,.3rem,(sm: .4rem,xs:.3rem));
        }
        .desc{
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          line-height: 1.7;
          @include res(height,.6rem,(md:.95rem,sm:1.25rem,xs:1rem));
          @include res(margin-bottom,.4rem,(sm: .5rem,xs:.4rem));
        }
        .date{
          color: $color-desc;
          @include res(font-size, .16rem,(md:.26rem,sm:.36rem,xs: .24rem));
        }
      }
      .swiper-pagination{
        position: absolute;
        text-align: left;
        @include res(bottom,.45rem,(xs:.35rem));
        @include res(padding-left, .7rem,(sm:1.2rem,xs:.7rem));
      }
      &:hover{
        background-color: #ececec;
        .swiper-button-next,.swiper-button-prev{
          opacity: 1;
          &.swiper-button-disabled{
            opacity: .2;
          }
        }
      }
    }
  }
  .sec4-list{
    @include res(width,calc(100% + .2rem),(xs:100%));
    @include res(display,flex,(xs:block));
    li{
      border-top-style: solid;
      border-top-color: #d9d9d9;
      position: relative;
      @include res(border-top-width, .02rem,(md:2px));
      @include res(width, calc(33.33% - .2rem),(xs:100%));
      @include res(margin-right, .2rem,(xs:0));
      @include res(padding-top,.35rem);
      @include res(margin-top,0,(xs: .4rem));
      &::after{
        content: '';
        display: block;
        width: 100%;
        background-color: $color-main-dark;
        position: absolute;
        left: 0;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform .3s;
        @include res(top, -.02rem,(md:-2px));
        @include res(height, .02rem,(md:2px));
      }
      .title{
        color: #000;
        line-height: 1.66;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        transition: color .3s;
        @include res(font-size, .24rem,(md:.34rem,sm:.44rem,xs:.34rem));
        @include res(margin-bottom, .25rem);
        @include res(height,.79rem,(md:1.15rem,sm:1.45rem,xs:1.15rem));
      }
      .date{
        color: $color-desc;
        @include res(font-size, .16rem,(md:.26rem,sm:.36rem,xs: .24rem));
      }
      &:hover{
        &::after{
          transform: scaleX(1);
        }
        .title{
          color: $color-main-dark;
        }
      }
    }
  }
}

.section5{
  .index-title .cn-title{
    @include res(display,flex,(xs:block));
    .sec5-tab{
      justify-content: center;
      color: $color-desc;
      @include res(display,flex);
      @include res(margin-top,0,(xs:.4rem));
      @include res(font-size, .22rem,(md:.32rem,sm:.42rem,xs:.32rem));
      >div{
        cursor: pointer;
        position: relative;
        transition: color .3s;
        @include res(padding-bottom,.07rem,(md:5px));
        &::after{
          content: '';
          display: block;
          width: 100%;
          background-color: $color-main-dark;
          position: absolute;
          left: 0;
          bottom: 0;
          transform: scaleX(0);
          transform-origin: center;
          transition: transform .3s;
          @include res(height, .03rem,(md:2px));
        }
        &:not(:last-child){
          @include res(margin-right,.55rem);
        }
        &.active{
          color: #000;
          &::after{
            transform: scaleX(1);
          }
        }
      }
    }
  }
  .swiper{
    border-radius: .2rem;
    overflow: hidden;
    @include res(height,38.54vw,(xs:95.54vw));
    .swiper-slide{
      position: relative;
      .item-img{
        height: 100%;
      }
      .item-t{
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        z-index: 1;
        width: 100%;
        height: 100%;
        @include res(padding-left,8.85vw,(xs:6vw));
        @include res(padding-top,9.89vw,(sm:7vw,xs:9vw));
        @include res(padding-right,8.85vw,(xs:6vw));
        &::after{
          content: '';
          display: block;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          @include res(width,70%,(md:80%,sm:100%));
        }
        .tag{
          color: #fff;
          @include res(font-size, .2rem,(md:.3rem,sm:.4rem,xs:.3rem));
        }
        .title{
          line-height: 1.37;
          color: #fff;
          @include res(font-size, .45rem,(md:.55rem,sm:.65rem,xs:.5rem));
          @include res(margin-top,.2rem);
          @include res(margin-bottom,.65rem);
          br{
            @include res(display,null,(xs:none));
          }
        }
      }
      &:nth-child(1){
        .item-t{
          &::after{
            background-image: linear-gradient(to right,rgba(5,58,116,1) 10%,rgba(5,58,116,0));
          }
        }
      }
      &:nth-child(2){
        .item-t{
          &::after{
            background-image: linear-gradient(to right,rgba(6,86,119,1) 10%,rgba(6,86,119,0));
          }
        }
      }
      &:nth-child(3){
        .item-t{
          &::after{
            background-image: linear-gradient(to right,rgba(9,78,8,1) 10%,rgba(9,78,8,0));
          }
        }
      }
    }
  }
}
