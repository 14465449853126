.section4 .sec4-swiper .swiper .swiper-slide .title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.index-title {
  margin-top: 1.75rem;
  margin-bottom: 0.4rem;
}

@media (max-width: 767px) {
  .index-title {
    margin-top: 1rem;
  }
}

.index-title .en-title {
  color: #28438b;
  font-family: 'Nexa_Bold';
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
  font-size: 0.2rem;
}

@media (max-width: 767px) {
  .index-title .en-title {
    margin-bottom: 0.1rem;
  }
}

@media (max-width: 1024px) {
  .index-title .en-title {
    font-size: 0.3rem;
  }
}

@media (max-width: 991px) {
  .index-title .en-title {
    font-size: 0.4rem;
  }
}

@media (max-width: 767px) {
  .index-title .en-title {
    font-size: 0.3rem;
  }
}

.index-title .en-title::after {
  content: '';
  display: block;
  flex-grow: 1;
  background-color: #cecece;
  margin-left: 0.2rem;
  height: 0.01rem;
}

@media (max-width: 1024px) {
  .index-title .en-title::after {
    height: 1px;
  }
}

.index-title .cn-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.index-title .cn-title h1 {
  color: #000;
  font-weight: bold;
  font-size: 0.45rem;
}

@media (max-width: 1024px) {
  .index-title .cn-title h1 {
    font-size: 0.55rem;
  }
}

@media (max-width: 991px) {
  .index-title .cn-title h1 {
    font-size: 0.65rem;
  }
}

@media (max-width: 767px) {
  .index-title .cn-title h1 {
    font-size: 0.5rem;
  }
}

.section1 {
  position: relative;
  z-index: 2;
  height: 100vh;
}

.section1 .swiper {
  height: 100%;
}

.section1 .swiper .swiper-slide {
  overflow: hidden;
}

.section1 .swiper .swiper-slide::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.section1 .swiper .swiper-slide b {
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.section1 .swiper .swiper-slide video {
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  object-fit: cover;
}

.section1 .banner-t {
  color: #fff;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
  bottom: 1.3rem;
}

.section1 .banner-t .t1 {
  font-size: 0.38rem;
}

@media (max-width: 1024px) {
  .section1 .banner-t .t1 {
    font-size: 0.48rem;
  }
}

@media (max-width: 991px) {
  .section1 .banner-t .t1 {
    font-size: 0.56rem;
  }
}

@media (max-width: 767px) {
  .section1 .banner-t .t1 {
    font-size: 0.4rem;
  }
}

.section1 .banner-t .t2 {
  margin-top: 0.05rem;
  font-size: 0.58rem;
}

@media (max-width: 1024px) {
  .section1 .banner-t .t2 {
    font-size: 0.68rem;
  }
}

@media (max-width: 991px) {
  .section1 .banner-t .t2 {
    font-size: 0.78rem;
  }
}

@media (max-width: 767px) {
  .section1 .banner-t .t2 {
    font-size: 0.54rem;
  }
}

.section1 .banner-t .btn {
  display: flex;
  align-items: center;
  margin-top: 0.4rem;
  font-size: 0.16rem;
}

@media (max-width: 1024px) {
  .section1 .banner-t .btn {
    font-size: 0.26rem;
  }
}

@media (max-width: 991px) {
  .section1 .banner-t .btn {
    font-size: 0.36rem;
  }
}

@media (max-width: 767px) {
  .section1 .banner-t .btn {
    font-size: 0.24rem;
  }
}

.section1 .banner-t .btn::before {
  content: '';
  display: block;
  flex-grow: 1;
  background-color: rgba(255, 255, 255, 0.3);
  height: 0.01rem;
}

@media (max-width: 1024px) {
  .section1 .banner-t .btn::before {
    height: 1px;
  }
}

.section1 .banner-t .btn span {
  margin-left: 0.15rem;
  margin-right: 0.15rem;
}

.section1 .banner-t .btn .icon {
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.5rem;
  height: 0.5rem;
}

@media (max-width: 1024px) {
  .section1 .banner-t .btn .icon {
    width: 0.6rem;
  }
}

@media (max-width: 991px) {
  .section1 .banner-t .btn .icon {
    width: 0.78rem;
  }
}

@media (max-width: 767px) {
  .section1 .banner-t .btn .icon {
    width: 0.7rem;
  }
}

@media (max-width: 1024px) {
  .section1 .banner-t .btn .icon {
    height: 0.6rem;
  }
}

@media (max-width: 991px) {
  .section1 .banner-t .btn .icon {
    height: 0.78rem;
  }
}

@media (max-width: 767px) {
  .section1 .banner-t .btn .icon {
    height: 0.7rem;
  }
}

.section1 .banner-t .btn .icon .iconfont {
  color: #07133f;
  animation: bandown 1.2s infinite linear;
  font-size: 0.2rem;
}

@media (max-width: 1024px) {
  .section1 .banner-t .btn .icon .iconfont {
    font-size: 0.3rem;
  }
}

@media (max-width: 991px) {
  .section1 .banner-t .btn .icon .iconfont {
    font-size: 0.4rem;
  }
}

@media (max-width: 767px) {
  .section1 .banner-t .btn .icon .iconfont {
    font-size: 0.34rem;
  }
}

@keyframes bandown {
  0% {
    opacity: 0;
    transform: translateY(-30%);
  }
  30% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(50%);
  }
}

.section2 .sec2-flex {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  height: 3.9rem;
}

@media (max-width: 991px) {
  .section2 .sec2-flex {
    height: 6rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-flex {
    height: 5rem;
  }
}

.section2 .sec2-flex .sec2-text {
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

@media (max-width: 767px) {
  .section2 .sec2-flex .sec2-text {
    display: block;
  }
}

.section2 .sec2-flex .sec2-text .left {
  flex-shrink: 0;
  line-height: 1.6;
  color: #000;
  width: 63vw;
  font-size: 0.45rem;
}

@media (max-width: 767px) {
  .section2 .sec2-flex .sec2-text .left {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .section2 .sec2-flex .sec2-text .left {
    font-size: 0.55rem;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-flex .sec2-text .left {
    font-size: 0.64rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-flex .sec2-text .left {
    font-size: 0.44rem;
  }
}

.section2 .sec2-flex .sec2-text .link {
  flex-shrink: 0;
  margin-top: 0;
  text-align: ull;
}

@media (max-width: 767px) {
  .section2 .sec2-flex .sec2-text .link {
    margin-top: 0.3rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-flex .sec2-text .link {
    text-align: right;
  }
}

.section2 .sec2-cont {
  position: relative;
  z-index: 1;
  display: flex;
}

@media (max-width: 991px) {
  .section2 .sec2-cont {
    display: block;
  }
}

.section2 .sec2-cont::after {
  content: '';
  display: block;
  width: 100%;
  height: 15%;
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 2;
  background-image: linear-gradient(to top, #e9f4ff, rgba(233, 244, 255, 0));
  border-bottom-left-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

.section2 .sec2-cont .bg {
  position: absolute;
  bottom: 0;
  z-index: -1;
  background-image: linear-gradient(to bottom, #4b9fe4, #eaf2ff);
  filter: blur(20px);
  transition: all 1.2s cubic-bezier(0.01, 0.17, 0.28, 0.96);
  transform: scale(1.05);
  width: calc(100% + 8.32vw);
  height: calc(100% + 3.9rem);
  left: -4.16vw;
  opacity: 0.4;
  border-radius: 0;
}

@media (max-width: 1024px) {
  .section2 .sec2-cont .bg {
    width: calc(100% + 2rem);
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .bg {
    width: calc(100% + .6rem);
  }
}

@media (max-width: 991px) {
  .section2 .sec2-cont .bg {
    height: calc(100% + 6rem);
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .bg {
    height: calc(100% + 5rem);
  }
}

@media (max-width: 1024px) {
  .section2 .sec2-cont .bg {
    left: -1rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .bg {
    left: -0.3rem;
  }
}

.section2 .sec2-cont .bg.active {
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: .2rem;
  opacity: 1;
  filter: blur(0px);
  transform: scale(1);
}

.section2 .sec2-cont .left {
  flex-shrink: 0;
  position: relative;
  width: 51.13%;
}

@media (max-width: 991px) {
  .section2 .sec2-cont .left {
    width: 100%;
  }
}

.section2 .sec2-cont .left .location-list li {
  position: absolute;
  width: 1px;
  height: 1px;
  white-space: nowrap;
}

.section2 .sec2-cont .left .location-list li span {
  color: #000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: color .3s;
  right: 0.25rem;
  font-size: 0.22rem;
}

@media (max-width: 1024px) {
  .section2 .sec2-cont .left .location-list li span {
    font-size: 0.32rem;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-cont .left .location-list li span {
    font-size: 0.42rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .left .location-list li span {
    font-size: 0.3rem;
  }
}

.section2 .sec2-cont .left .location-list li .icon {
  border-radius: 50%;
  background-color: #099aff;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate3d(-50%, -50%, 0);
  width: 0.13rem;
  height: 0.13rem;
}

@media (max-width: 1024px) {
  .section2 .sec2-cont .left .location-list li .icon {
    width: 0.16rem;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-cont .left .location-list li .icon {
    width: 0.2rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .left .location-list li .icon {
    width: 0.16rem;
  }
}

@media (max-width: 1024px) {
  .section2 .sec2-cont .left .location-list li .icon {
    height: 0.16rem;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-cont .left .location-list li .icon {
    height: 0.2rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .left .location-list li .icon {
    height: 0.16rem;
  }
}

.section2 .sec2-cont .left .location-list li .icon::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #099aff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform-origin: center;
  border-radius: 50%;
  animation: mapscroll 2s infinite;
  animation-delay: 1s;
}

.section2 .sec2-cont .left .location-list li .icon::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #099aff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform-origin: center;
  border-radius: 50%;
  animation: mapscroll 2s infinite;
}

.section2 .sec2-cont .left .location-list li:nth-child(1) {
  top: 57%;
  left: 72.5%;
}

.section2 .sec2-cont .left .location-list li:nth-child(1) .icon {
  background-color: #d53030;
}

.section2 .sec2-cont .left .location-list li:nth-child(1) .icon::before, .section2 .sec2-cont .left .location-list li:nth-child(1) .icon::after {
  background-color: #d53030;
}

.section2 .sec2-cont .left .location-list li:nth-child(2) {
  top: 79%;
  left: 40.5%;
}

.section2 .sec2-cont .left .location-list li:nth-child(3) {
  top: 44%;
  left: 75.5%;
}

.section2 .sec2-cont .left .location-list li.active span {
  color: #000;
}

.section2 .sec2-cont .left .location-list li.active .icon::before {
  animation: mapscroll 2s infinite;
  animation-delay: 1s;
}

.section2 .sec2-cont .left .location-list li.active .icon::after {
  animation: mapscroll 2s infinite;
}

.section2 .sec2-cont .left .location-tab {
  position: absolute;
  z-index: 2;
  border-radius: .15rem;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.38);
  white-space: nowrap;
  top: 5.2vw;
  left: 7.29vw;
  font-size: 0.2rem;
}

@media (max-width: 1024px) {
  .section2 .sec2-cont .left .location-tab {
    top: 2.2vw;
  }
}

@media (max-width: 1024px) {
  .section2 .sec2-cont .left .location-tab {
    left: 2.29vw;
  }
}

@media (max-width: 1024px) {
  .section2 .sec2-cont .left .location-tab {
    font-size: 0.3rem;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-cont .left .location-tab {
    font-size: 0.4rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .left .location-tab {
    font-size: 0.24rem;
  }
}

.section2 .sec2-cont .left .location-tab li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: color .3s, background-color .3s;
  padding: 0.2rem 0.25rem;
}

@media (max-width: 767px) {
  .section2 .sec2-cont .left .location-tab li {
    padding: 0.15rem 0.1rem;
  }
}

.section2 .sec2-cont .left .location-tab li::before {
  content: '';
  display: block;
  background-color: #099aff;
  border-radius: 50%;
  transition: background-color .3s;
  width: 0.08rem;
  height: 0.08rem;
  margin-left: 0.05rem;
  margin-right: 0.15rem;
}

@media (max-width: 1024px) {
  .section2 .sec2-cont .left .location-tab li::before {
    width: 0.1rem;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-cont .left .location-tab li::before {
    width: 0.14rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .left .location-tab li::before {
    width: 0.08rem;
  }
}

@media (max-width: 1024px) {
  .section2 .sec2-cont .left .location-tab li::before {
    height: 0.1rem;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-cont .left .location-tab li::before {
    height: 0.14rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .left .location-tab li::before {
    height: 0.08rem;
  }
}

.section2 .sec2-cont .left .location-tab li:nth-child(1)::before {
  background-color: #d53030;
}

.section2 .sec2-cont .left .location-tab li.active {
  color: #fff;
  background-color: #099aff;
}

.section2 .sec2-cont .left .location-tab li.active::before {
  background-color: #fff;
}

.section2 .sec2-cont .left .location-tab li.active:nth-child(1) {
  background-color: #d53030;
}

.section2 .sec2-cont .right {
  flex-shrink: 0;
  position: relative;
  z-index: 1;
  width: 48.87%;
}

@media (max-width: 991px) {
  .section2 .sec2-cont .right {
    width: 100%;
  }
}

.section2 .sec2-cont .right .num-cont {
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  color: #000;
  justify-content: flex-start;
  font-size: 0.16rem;
  margin-top: 1rem;
}

@media (max-width: 991px) {
  .section2 .sec2-cont .right .num-cont {
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .section2 .sec2-cont .right .num-cont {
    font-size: 0.26rem;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-cont .right .num-cont {
    font-size: 0.36rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .right .num-cont {
    font-size: 0.24rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .right .num-cont {
    margin-top: 0.5rem;
  }
}

.section2 .sec2-cont .right .num-cont .num-item {
  box-sizing: border-box;
  min-width: 33.33%;
  margin-bottom: 0.25rem;
  padding-left: 0.2rem;
  padding-right: 0;
}

@media (max-width: 767px) {
  .section2 .sec2-cont .right .num-cont .num-item {
    text-align: center;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-cont .right .num-cont .num-item {
    min-width: auto;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-cont .right .num-cont .num-item {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 1024px) {
  .section2 .sec2-cont .right .num-cont .num-item {
    padding-left: 0.2rem;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-cont .right .num-cont .num-item {
    padding-left: 1rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .right .num-cont .num-item {
    padding-left: 0.4rem;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-cont .right .num-cont .num-item {
    padding-right: 1rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .right .num-cont .num-item {
    padding-right: 0.4rem;
  }
}

.section2 .sec2-cont .right .num-cont .num-item .t1 {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 0.1rem;
}

.section2 .sec2-cont .right .num-cont .num-item .t1 .number {
  display: inline-block;
  position: relative;
  font-family: 'Nexa_Bold';
  color: #28438b;
  font-size: 0.6rem;
  line-height: 0.6rem;
}

@media (max-width: 991px) {
  .section2 .sec2-cont .right .num-cont .num-item .t1 .number {
    font-size: 1rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .right .num-cont .num-item .t1 .number {
    font-size: 0.7rem;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-cont .right .num-cont .num-item .t1 .number {
    line-height: 1rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .right .num-cont .num-item .t1 .number {
    line-height: 0.7rem;
  }
}

.section2 .sec2-cont .right .num-cont .num-item .t1 .number .zw-num {
  opacity: 0;
}

.section2 .sec2-cont .right .num-cont .num-item .t1 .number .num {
  position: absolute;
  top: 0;
  left: 0;
}

.section2 .sec2-cont .right .num-cont .num-item .t1 .number .num .dataNums {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 0.6rem;
}

@media (max-width: 991px) {
  .section2 .sec2-cont .right .num-cont .num-item .t1 .number .num .dataNums {
    height: 1rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .right .num-cont .num-item .t1 .number .num .dataNums {
    height: 0.7rem;
  }
}

.section2 .sec2-cont .right .num-cont .num-item .t1 .number .num .dataNums .dataOne {
  width: 0.4rem;
  height: 0.6rem;
}

@media (max-width: 991px) {
  .section2 .sec2-cont .right .num-cont .num-item .t1 .number .num .dataNums .dataOne {
    width: 0.66rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .right .num-cont .num-item .t1 .number .num .dataNums .dataOne {
    width: 0.46rem;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-cont .right .num-cont .num-item .t1 .number .num .dataNums .dataOne {
    height: 1rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .right .num-cont .num-item .t1 .number .num .dataNums .dataOne {
    height: 0.7rem;
  }
}

.section2 .sec2-cont .right .num-cont .num-item .t1 .number .num .dataNums .dataOne.dh {
  box-sizing: border-box;
  width: 0.4rem;
}

.section2 .sec2-cont .right .num-cont .num-item .t1 .number .num .dataNums .dataBoc {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.section2 .sec2-cont .right .num-cont .num-item .t1 .number .num .dataNums .dataBoc .tt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section2 .sec2-cont .right .num-cont .num-item .t1 .number .num .dataNums .dataBoc .tt span {
  width: 100%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
}

.section2 .sec2-cont .right .num-cont .num-item .t1 .jia {
  position: absolute;
  top: 0;
  left: 100%;
  color: #28438b;
  line-height: .2;
  font-size: 0.3rem;
}

@media (max-width: 1024px) {
  .section2 .sec2-cont .right .num-cont .num-item .t1 .jia {
    font-size: 0.4rem;
  }
}

@media (max-width: 991px) {
  .section2 .sec2-cont .right .num-cont .num-item .t1 .jia {
    font-size: 0.6rem;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .right .num-cont .num-item .t1 .jia {
    font-size: 0.46rem;
  }
}

.section2 .sec2-cont .right .num-cont .num-item .t1 .dw {
  margin-left: 0.15rem;
  margin-bottom: 0.07rem;
}

@media (max-width: 1024px) {
  .section2 .sec2-cont .right .num-cont .num-item .t1 .dw {
    margin-left: 0.2rem;
  }
}

.section2 .sec2-cont .right .img {
  bottom: 0;
  right: 0;
  z-index: -1;
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
  position: absolute;
  margin-top: 0;
}

@media (max-width: 991px) {
  .section2 .sec2-cont .right .img {
    position: static;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .right .img {
    margin-top: -10%;
  }
}

.section2 .sec2-cont .right .img img {
  display: block;
  height: auto;
  border-bottom-right-radius: .2rem;
  width: 125%;
}

@media (max-width: 991px) {
  .section2 .sec2-cont .right .img img {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .section2 .sec2-cont .right .img img {
    width: 110%;
  }
}

@keyframes mapscroll {
  0% {
    opacity: .8;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(3);
  }
}

.section3 ul {
  flex-wrap: nowrap;
  width: calc(100% + .25rem);
  display: flex;
}

@media (max-width: 991px) {
  .section3 ul {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .section3 ul {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .section3 ul {
    display: block;
  }
}

.section3 ul li {
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: .2rem;
  background-color: #2543ab;
  transition: width .4s;
  margin-right: 0.25rem;
  width: calc(25% - .25rem);
  height: 39.8vw;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .section3 ul li {
    width: calc(50% - .25rem);
  }
}

@media (max-width: 767px) {
  .section3 ul li {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .section3 ul li {
    height: 48.8vw;
  }
}

@media (max-width: 767px) {
  .section3 ul li {
    height: 101.8vw;
  }
}

@media (max-width: 991px) {
  .section3 ul li {
    margin-bottom: 0.25rem;
  }
}

.section3 ul li .img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left bottom;
}

.section3 ul li .title {
  color: #fff;
  white-space: nowrap;
  position: relative;
  z-index: 1;
  padding-top: 1rem;
  padding-left: 0.45rem;
  font-size: 0.35rem;
}

@media (max-width: 767px) {
  .section3 ul li .title {
    padding-top: 0.6rem;
  }
}

@media (max-width: 1024px) {
  .section3 ul li .title {
    font-size: 0.45rem;
  }
}

@media (max-width: 991px) {
  .section3 ul li .title {
    font-size: 0.6rem;
  }
}

@media (max-width: 767px) {
  .section3 ul li .title {
    font-size: 0.48rem;
  }
}

.section3 ul li .link {
  position: absolute;
  z-index: 1;
  transition: opacity .3s;
  transition-delay: 0s;
  opacity: 0;
  bottom: 0.45rem;
  right: 0.4rem;
}

@media (max-width: 991px) {
  .section3 ul li .link {
    opacity: 1;
  }
}

.section3 ul li:nth-child(1) {
  background-image: linear-gradient(to bottom, #2542ab, #25b4ac);
}

.section3 ul li:nth-child(2) {
  background-image: linear-gradient(to bottom, #1868d4, #30c9cd);
}

.section3 ul li:nth-child(2) .img {
  background-position: right bottom;
}

.section3 ul li:nth-child(3) {
  background-image: linear-gradient(to bottom, #2b7bb2, #a4c9d0);
}

.section3 ul li:nth-child(3) .img {
  background-position: right bottom;
}

.section3 ul li:nth-child(4) {
  background-image: linear-gradient(to bottom, #077bbb, #c58d8e);
}

.section3 ul li.active {
  width: calc(37% - .25rem);
}

@media (max-width: 991px) {
  .section3 ul li.active {
    width: calc(50% - .25rem);
  }
}

@media (max-width: 767px) {
  .section3 ul li.active {
    width: 100%;
  }
}

.section3 ul li.active .link {
  opacity: 1;
  transition-delay: .4s;
}

.section3 ul li.other {
  width: calc(21% - .25rem);
}

@media (max-width: 991px) {
  .section3 ul li.other {
    width: calc(50% - .25rem);
  }
}

@media (max-width: 767px) {
  .section3 ul li.other {
    width: 100%;
  }
}

.section4 .sec4-swiper {
  justify-content: space-between;
  flex-direction: row-reverse;
  display: flex;
  margin-bottom: 0.6rem;
}

@media (max-width: 991px) {
  .section4 .sec4-swiper {
    display: block;
  }
}

.section4 .sec4-swiper .img {
  position: relative;
  flex-shrink: 0;
  border-radius: .1rem;
  overflow: hidden;
  width: 29.79vw;
  height: 18.95vw;
}

@media (max-width: 991px) {
  .section4 .sec4-swiper .img {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .section4 .sec4-swiper .img {
    height: 21.95vw;
  }
}

@media (max-width: 991px) {
  .section4 .sec4-swiper .img {
    height: 45.95vw;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-swiper .img {
    height: 55vw;
  }
}

.section4 .sec4-swiper .img a {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  transition: transform .3s;
}

.section4 .sec4-swiper .img a:hover {
  transform: scale(1.1);
}

.section4 .sec4-swiper .swiper {
  border-radius: .1rem;
  overflow: hidden;
  background-color: #f4f4f4;
  margin: 0;
  transition: background-color .3s;
  width: calc(100% - .2rem - 29.79vw);
}

@media (max-width: 991px) {
  .section4 .sec4-swiper .swiper {
    width: 100%;
  }
}

.section4 .sec4-swiper .swiper .swiper-slide {
  box-sizing: border-box;
  padding: 0.7rem;
  padding-bottom: 1rem;
}

@media (max-width: 991px) {
  .section4 .sec4-swiper .swiper .swiper-slide {
    padding: 1.2rem;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-swiper .swiper .swiper-slide {
    padding: 0.5rem 0.7rem;
  }
}

@media (max-width: 991px) {
  .section4 .sec4-swiper .swiper .swiper-slide {
    padding-bottom: 1.5rem;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-swiper .swiper .swiper-slide {
    padding-bottom: 1rem;
  }
}

.section4 .sec4-swiper .swiper .swiper-slide .title {
  color: #000;
  font-size: 0.3rem;
  margin-bottom: 0.3rem;
}

@media (max-width: 1024px) {
  .section4 .sec4-swiper .swiper .swiper-slide .title {
    font-size: 0.4rem;
  }
}

@media (max-width: 991px) {
  .section4 .sec4-swiper .swiper .swiper-slide .title {
    font-size: 0.56rem;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-swiper .swiper .swiper-slide .title {
    font-size: 0.4rem;
  }
}

@media (max-width: 991px) {
  .section4 .sec4-swiper .swiper .swiper-slide .title {
    margin-bottom: 0.4rem;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-swiper .swiper .swiper-slide .title {
    margin-bottom: 0.3rem;
  }
}

.section4 .sec4-swiper .swiper .swiper-slide .desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 1.7;
  height: 0.6rem;
  margin-bottom: 0.4rem;
}

@media (max-width: 1024px) {
  .section4 .sec4-swiper .swiper .swiper-slide .desc {
    height: 0.95rem;
  }
}

@media (max-width: 991px) {
  .section4 .sec4-swiper .swiper .swiper-slide .desc {
    height: 1.25rem;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-swiper .swiper .swiper-slide .desc {
    height: 1rem;
  }
}

@media (max-width: 991px) {
  .section4 .sec4-swiper .swiper .swiper-slide .desc {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-swiper .swiper .swiper-slide .desc {
    margin-bottom: 0.4rem;
  }
}

.section4 .sec4-swiper .swiper .swiper-slide .date {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.16rem;
}

@media (max-width: 1024px) {
  .section4 .sec4-swiper .swiper .swiper-slide .date {
    font-size: 0.26rem;
  }
}

@media (max-width: 991px) {
  .section4 .sec4-swiper .swiper .swiper-slide .date {
    font-size: 0.36rem;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-swiper .swiper .swiper-slide .date {
    font-size: 0.24rem;
  }
}

.section4 .sec4-swiper .swiper .swiper-pagination {
  position: absolute;
  text-align: left;
  bottom: 0.45rem;
  padding-left: 0.7rem;
}

@media (max-width: 767px) {
  .section4 .sec4-swiper .swiper .swiper-pagination {
    bottom: 0.35rem;
  }
}

@media (max-width: 991px) {
  .section4 .sec4-swiper .swiper .swiper-pagination {
    padding-left: 1.2rem;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-swiper .swiper .swiper-pagination {
    padding-left: 0.7rem;
  }
}

.section4 .sec4-swiper .swiper:hover {
  background-color: #ececec;
}

.section4 .sec4-swiper .swiper:hover .swiper-button-next, .section4 .sec4-swiper .swiper:hover .swiper-button-prev {
  opacity: 1;
}

.section4 .sec4-swiper .swiper:hover .swiper-button-next.swiper-button-disabled, .section4 .sec4-swiper .swiper:hover .swiper-button-prev.swiper-button-disabled {
  opacity: .2;
}

.section4 .sec4-list {
  width: calc(100% + .2rem);
  display: flex;
}

@media (max-width: 767px) {
  .section4 .sec4-list {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-list {
    display: block;
  }
}

.section4 .sec4-list li {
  border-top-style: solid;
  border-top-color: #d9d9d9;
  position: relative;
  border-top-width: 0.02rem;
  width: calc(33.33% - .2rem);
  margin-right: 0.2rem;
  padding-top: 0.35rem;
  margin-top: 0;
}

@media (max-width: 1024px) {
  .section4 .sec4-list li {
    border-top-width: 2px;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-list li {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-list li {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-list li {
    margin-top: 0.4rem;
  }
}

.section4 .sec4-list li::after {
  content: '';
  display: block;
  width: 100%;
  background-color: #07133f;
  position: absolute;
  left: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform .3s;
  top: -0.02rem;
  height: 0.02rem;
}

@media (max-width: 1024px) {
  .section4 .sec4-list li::after {
    top: -2px;
  }
}

@media (max-width: 1024px) {
  .section4 .sec4-list li::after {
    height: 2px;
  }
}

.section4 .sec4-list li .title {
  color: #000;
  line-height: 1.66;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  transition: color .3s;
  font-size: 0.24rem;
  margin-bottom: 0.25rem;
  height: 0.79rem;
}

@media (max-width: 1024px) {
  .section4 .sec4-list li .title {
    font-size: 0.34rem;
  }
}

@media (max-width: 991px) {
  .section4 .sec4-list li .title {
    font-size: 0.44rem;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-list li .title {
    font-size: 0.34rem;
  }
}

@media (max-width: 1024px) {
  .section4 .sec4-list li .title {
    height: 1.15rem;
  }
}

@media (max-width: 991px) {
  .section4 .sec4-list li .title {
    height: 1.45rem;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-list li .title {
    height: 1.15rem;
  }
}

.section4 .sec4-list li .date {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.16rem;
}

@media (max-width: 1024px) {
  .section4 .sec4-list li .date {
    font-size: 0.26rem;
  }
}

@media (max-width: 991px) {
  .section4 .sec4-list li .date {
    font-size: 0.36rem;
  }
}

@media (max-width: 767px) {
  .section4 .sec4-list li .date {
    font-size: 0.24rem;
  }
}

.section4 .sec4-list li:hover::after {
  transform: scaleX(1);
}

.section4 .sec4-list li:hover .title {
  color: #07133f;
}

.section5 .index-title .cn-title {
  display: flex;
}

@media (max-width: 767px) {
  .section5 .index-title .cn-title {
    display: block;
  }
}

.section5 .index-title .cn-title .sec5-tab {
  justify-content: center;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  margin-top: 0;
  font-size: 0.22rem;
}

@media (max-width: 767px) {
  .section5 .index-title .cn-title .sec5-tab {
    margin-top: 0.4rem;
  }
}

@media (max-width: 1024px) {
  .section5 .index-title .cn-title .sec5-tab {
    font-size: 0.32rem;
  }
}

@media (max-width: 991px) {
  .section5 .index-title .cn-title .sec5-tab {
    font-size: 0.42rem;
  }
}

@media (max-width: 767px) {
  .section5 .index-title .cn-title .sec5-tab {
    font-size: 0.32rem;
  }
}

.section5 .index-title .cn-title .sec5-tab > div {
  cursor: pointer;
  position: relative;
  transition: color .3s;
  padding-bottom: 0.07rem;
}

@media (max-width: 1024px) {
  .section5 .index-title .cn-title .sec5-tab > div {
    padding-bottom: 5px;
  }
}

.section5 .index-title .cn-title .sec5-tab > div::after {
  content: '';
  display: block;
  width: 100%;
  background-color: #07133f;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform .3s;
  height: 0.03rem;
}

@media (max-width: 1024px) {
  .section5 .index-title .cn-title .sec5-tab > div::after {
    height: 2px;
  }
}

.section5 .index-title .cn-title .sec5-tab > div:not(:last-child) {
  margin-right: 0.55rem;
}

.section5 .index-title .cn-title .sec5-tab > div.active {
  color: #000;
}

.section5 .index-title .cn-title .sec5-tab > div.active::after {
  transform: scaleX(1);
}

.section5 .swiper {
  border-radius: .2rem;
  overflow: hidden;
  height: 38.54vw;
}

@media (max-width: 767px) {
  .section5 .swiper {
    height: 95.54vw;
  }
}

.section5 .swiper .swiper-slide {
  position: relative;
}

.section5 .swiper .swiper-slide .item-img {
  height: 100%;
}

.section5 .swiper .swiper-slide .item-t {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding-left: 8.85vw;
  padding-top: 9.89vw;
  padding-right: 8.85vw;
}

@media (max-width: 767px) {
  .section5 .swiper .swiper-slide .item-t {
    padding-left: 6vw;
  }
}

@media (max-width: 991px) {
  .section5 .swiper .swiper-slide .item-t {
    padding-top: 7vw;
  }
}

@media (max-width: 767px) {
  .section5 .swiper .swiper-slide .item-t {
    padding-top: 9vw;
  }
}

@media (max-width: 767px) {
  .section5 .swiper .swiper-slide .item-t {
    padding-right: 6vw;
  }
}

.section5 .swiper .swiper-slide .item-t::after {
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 70%;
}

@media (max-width: 1024px) {
  .section5 .swiper .swiper-slide .item-t::after {
    width: 80%;
  }
}

@media (max-width: 991px) {
  .section5 .swiper .swiper-slide .item-t::after {
    width: 100%;
  }
}

.section5 .swiper .swiper-slide .item-t .tag {
  color: #fff;
  font-size: 0.2rem;
}

@media (max-width: 1024px) {
  .section5 .swiper .swiper-slide .item-t .tag {
    font-size: 0.3rem;
  }
}

@media (max-width: 991px) {
  .section5 .swiper .swiper-slide .item-t .tag {
    font-size: 0.4rem;
  }
}

@media (max-width: 767px) {
  .section5 .swiper .swiper-slide .item-t .tag {
    font-size: 0.3rem;
  }
}

.section5 .swiper .swiper-slide .item-t .title {
  line-height: 1.37;
  color: #fff;
  font-size: 0.45rem;
  margin-top: 0.2rem;
  margin-bottom: 0.65rem;
}

@media (max-width: 1024px) {
  .section5 .swiper .swiper-slide .item-t .title {
    font-size: 0.55rem;
  }
}

@media (max-width: 991px) {
  .section5 .swiper .swiper-slide .item-t .title {
    font-size: 0.65rem;
  }
}

@media (max-width: 767px) {
  .section5 .swiper .swiper-slide .item-t .title {
    font-size: 0.5rem;
  }
}

@media (max-width: 767px) {
  .section5 .swiper .swiper-slide .item-t .title br {
    display: none;
  }
}

.section5 .swiper .swiper-slide:nth-child(1) .item-t::after {
  background-image: linear-gradient(to right, #053a74 10%, rgba(5, 58, 116, 0));
}

.section5 .swiper .swiper-slide:nth-child(2) .item-t::after {
  background-image: linear-gradient(to right, #065677 10%, rgba(6, 86, 119, 0));
}

.section5 .swiper .swiper-slide:nth-child(3) .item-t::after {
  background-image: linear-gradient(to right, #094e08 10%, rgba(9, 78, 8, 0));
}
